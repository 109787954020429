import * as React from 'react';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {motion} from "framer-motion";
import {enteringFormTransition, inputTransition} from "../../constants/transitions";
import Grid from "@mui/material/Grid";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {UploadFile, useUploader} from "../../components/UploadFile";
import Download from "@mui/icons-material/Download";
import { Visa } from '@styled-icons/boxicons-logos/Visa';
import { Mastercard } from '@styled-icons/boxicons-logos/Mastercard';
import withTheme from "../../components/WithTheme";
import {Autocomplete, CardActionArea, FormControl, FormControlLabel, Radio, RadioGroup, Stack} from "@mui/material";
import { SliderPicker } from 'react-color';
import IconButton from "@mui/material/IconButton";
import {Colorize} from "@mui/icons-material";
import {navigate} from "gatsby";

const ComponentToPrint = React.forwardRef((props:any, ref) => (
    <div ref={ ref }>
        <Card

            sx={{
                my: 3,
                position: 'relative',
                borderRadius: 3,
                minHeight: 180,
                backgroundColor: 'black',
            }} elevation={ 3 }>
            {
                props?.dataUri && (
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            position: 'absolute',
                            objectFit: 'cover',
                            objectPosition: 'center'
                        }}
                        src={ props?.dataUri }
                        alt=""
                    />
                )
            }

            <CardContent sx={{
                position: 'absolute',
                bottom: 0
            }}>
                {
                    props?.cardData.clabe && (
                        <>
                            <Typography
                                sx={{ color: props?.cardData.colorClabe?.hex, fontSize: 10 }}
                            >
                                CLABE
                            </Typography>
                            <Typography
                                sx={{
                                    color: props?.cardData.colorClabe?.hex
                                }}
                                variant="h6"
                            >
                                { props?.cardData.clabe }
                            </Typography>
                        </>

                    )
                }
                {
                    props?.cardData.cardNumber && (
                        <>
                            <Typography
                                sx={{ color: props?.cardData.colorNumber?.hex, fontSize: 10 }}
                            >
                                Número de tarjeta
                            </Typography>
                            <Typography
                                sx={{
                                    color: props?.cardData.colorNumber?.hex,
                                    fontSize: 14
                                }}
                            >
                                { props?.cardData.cardNumber }
                            </Typography>
                        </>

                    )
                }
                {
                    props?.cardData.name && (
                        <>
                            <Typography
                                sx={{
                                    color: props?.cardData.colorName?.hex,
                                    fontSize: 14
                                }}
                            >
                                { props?.cardData.name }
                            </Typography>
                        </>

                    )
                }


            </CardContent>
            <CardContent>
                {
                    props?.cardData.bank && (
                        <Typography
                            style={{
                                position: 'absolute',
                                right: 16,
                                top: 8,
                                fontWeight: 900,
                                color: 'white',
                            }}
                        >
                            { props?.cardData.bank.label }
                        </Typography>
                    )
                }
                {
                    props?.cardData.brand === 'visa' ? (
                        <Visa
                            width={ 40 }
                            height={ 40 }
                            style={{
                                zIndex: 2,
                                position: 'relative',
                                color: 'white',
                            }}/>
                    ) : (
                        <Mastercard
                            width={ 40 }
                            height={ 40 }
                            style={{
                                zIndex: 2,
                                position: 'relative',
                                color: 'white',
                            }}/>
                    )
                }
            </CardContent>
        </Card>
    </div>

));

const banks = [
    { label: 'American Express', value: 'american_express' },
    { label: 'Banco Azteca', value: 'banco_azteca' },
    { label: 'Banco Inbursa', value: 'inbursa' },
    { label: 'Banregio', value: 'banregio' },
    { label: 'Santander', value: 'santander' },
    { label: 'BanCoppel', value: 'bancoppel' },
    { label: 'Banorte', value: 'banorte' },
    { label: 'BBVA', value: 'bbva' },
    { label: 'Citibanamex', value: 'citibanamex' },
    { label: 'HSBC México', value: 'hsbc' },
    { label: 'Scotiabank', value: 'scotiabank' },
]
const Tarjeta = () => {
    const [ plugin, setPlugin ] = React.useState<any>( null );
    const { handleSubmit, control, formState: {errors} } = useForm();
    const componentRef = React.useRef();
    const [ cardData, setCardData ] = React.useState<any>( {
        clabe: undefined,
        cardNumber: undefined,
        name: undefined,
        colorClabe: '#ffffff',
        colorName: '#ffffff',
        colorNumber: '#ffffff',
        brand: undefined,
        bank: undefined
    });
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const { dataUri, onChange, handleDelete, imageSrc, openModal, handleModal, getCropData, setCropper, cropper, temporalDataUri } = useUploader( true );
    const [ colorPickers, setColorPickers ]  = React.useState({
        colorClabe: false,
        colorName: false,
        colorNumber: false,
    })

    React.useEffect(() => {
        if( window !== undefined ) {
            // @ts-ignore
            setPlugin( require('react-component-export-image' ) );
        }
    },[ ]);
    const onSubmit = async (data: any) => {

        setLoading( true );


        if( plugin ) {
            await plugin.exportComponentAsPNG(componentRef, {
                html2CanvasOptions: {
                    scale: 4,
                    backgroundColor: null
                }
            })
        }

        setLoading( false );
    };



    return(
        <Layout>
            <Seo
                title="¡Crea tu tarjeta personalizada!"
                description="Compártela fácilmente con tus clientes."
            />
            <Container maxWidth="xs" sx={{ mt: 12 }}>
                <ComponentToPrint
                    cardData={ cardData }
                    dataUri={ dataUri }
                    ref={componentRef}
                />
                <UploadFile
                    useCropper={ true }
                    title="Sube un fondo para tu tarjeta"
                    onChange={ onChange }
                    handleDelete={ handleDelete }
                    dataUri={ dataUri }
                    imageSrc={ imageSrc }
                    openModal={ openModal }
                    cropper={cropper}
                    getCropData={ getCropData }
                    handleModal={ handleModal }
                    setCropper={ setCropper }
                    temporalDataUri={ temporalDataUri }
                    maxFileSize={ 20971520 }
                    aspectRatio={ [ 1.86, 1, 0.86 ] }
                />
                <form>
                    <motion.div initial='initial' animate='animate' exit='exit'>
                        <motion.span variants={enteringFormTransition}>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 10 } item>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={ (e) => {
                                                        setCardData( (prev:any) => ({
                                                            ...prev,
                                                            name: e.target.value
                                                        }));
                                                        onChange( e );
                                                    }}
                                                    value={value}
                                                    label="Tu Nombre"
                                                />
                                            )}
                                        />
                                        {errors.name && <Typography variant="caption" sx={{color:'red'}}>{errors.name.message}</Typography>}
                                    </Grid>
                                    <Grid xs={ 2 } alignItems="center" justifyContent="center" display="flex" item>
                                        <IconButton
                                            color={ colorPickers.colorName ? 'primary' : 'default' }
                                            onClick={ () => setColorPickers(  (prev:any) => ({
                                                ...prev,
                                                colorName: !colorPickers.colorName
                                            }))}
                                        >
                                            <Colorize/>
                                        </IconButton>

                                    </Grid>
                                    {
                                        colorPickers.colorName && (
                                            <Grid sx={{mt: 2}} item xs={12}>
                                                <SliderPicker
                                                    color={ cardData.colorName }
                                                    onChangeComplete={ (color) => {
                                                        setCardData((prev:any) => ({
                                                            ...prev,
                                                            colorName: color
                                                        }))
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>

                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 10 } item>
                                        <Controller
                                            name="clabe"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        type: 'number'
                                                    }}
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={ (e) => {
                                                        onChange( e );

                                                        let splitText = e.target.value.match(/.{1,4}/g);

                                                        if( !splitText ) return;

                                                        setCardData( (prev:any) => ({
                                                            ...prev,
                                                            clabe: splitText.join(' ')
                                                        }));
                                                    }}
                                                    value={value}
                                                    label="Tu CLABE a 16 dígitos"
                                                />
                                            )}
                                        />
                                        { errors.clabe && <Typography variant="caption" sx={{color:'red'}}>{errors.clabe.message}</Typography>}
                                    </Grid>
                                    <Grid xs={ 2 } alignItems="center" justifyContent="center" display="flex" item>
                                        <IconButton
                                            color={ colorPickers.colorClabe ? 'primary' : 'default' }
                                            onClick={ () => setColorPickers(  (prev:any) => ({
                                                ...prev,
                                                colorClabe: !colorPickers.colorClabe
                                            }))}
                                        >
                                            <Colorize/>
                                        </IconButton>

                                    </Grid>
                                    {
                                        colorPickers.colorClabe && (
                                            <Grid sx={{mt: 2}} item xs={12}>
                                                <SliderPicker
                                                    color={ cardData.colorClabe }
                                                    onChangeComplete={ (color) => {
                                                        setCardData((prev:any) => ({
                                                            ...prev,
                                                            colorClabe: color
                                                        }))
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 10 } item>
                                        <Controller
                                            name="cardNumber"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        type: 'number'
                                                    }}
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={ (e) => {
                                                        onChange( e );

                                                        let splitText = e.target.value.match(/.{1,4}/g);
                                                        if( !splitText ) return;

                                                        setCardData( (prev:any) => ({
                                                            ...prev,
                                                            cardNumber: splitText.join(' ')
                                                        }));

                                                    }}
                                                    value={value}
                                                    label="Tu número de tarjeta"
                                                />
                                            )}
                                        />
                                        {errors.cardNumber && <Typography variant="caption" sx={{color:'red'}}>{errors.cardNumber.message}</Typography>}
                                    </Grid>
                                    <Grid xs={ 2 } alignItems="center" justifyContent="center" display="flex" item>
                                        <IconButton
                                            color={ colorPickers.colorNumber ? 'primary' : 'default' }
                                            onClick={ () => setColorPickers(  (prev:any) => ({
                                                ...prev,
                                                colorNumber: !colorPickers.colorNumber
                                            }))}
                                        >
                                            <Colorize/>
                                        </IconButton>

                                    </Grid>
                                    {
                                        colorPickers.colorNumber && (
                                            <Grid sx={{mt: 2}} item xs={12}>
                                                <SliderPicker
                                                    color={ cardData.colorNumber }
                                                    onChangeComplete={ (color) => {
                                                        setCardData((prev:any) => ({
                                                            ...prev,
                                                            colorNumber: color
                                                        }))
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 12 } item>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={ (e) => {
                                                    setCardData((prev:any) => ({
                                                        ...prev,
                                                        brand: e.target.value
                                                    }))
                                                }}
                                            >
                                                <FormControlLabel sx={{ color: 'white' }} value="visa" control={<Radio />} label="Visa"  defaultChecked={ true }/>
                                                <FormControlLabel sx={{ color: 'white' }} value="master_card" control={<Radio />} label="Master Card" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Grid mt={2} mb={2} container>
                                    <Grid xs={ 12 } item>
                                        <Autocomplete
                                            onChange={ (e, value) => {
                                                setCardData((prev:any) => ({
                                                    ...prev,
                                                    bank: value
                                                }))
                                            }}
                                            fullWidth
                                            disablePortal
                                            id="combo-box-demo"
                                            options={banks}
                                            renderInput={(params) =>
                                                <TextField {...params}

                                                           label="Banco" variant="filled"
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </motion.div>
                            <motion.div variants={inputTransition}>
                                <Button
                                    disabled={ loading }
                                    sx={{
                                        width: '100%',
                                        color: 'white',
                                        padding: '14px 0',
                                        borderRadius: 3.5,
                                        textTransform: 'none',
                                    }}
                                    variant="contained"
                                    startIcon={ loading ? <CircularProgress sx={{ color:'inherit' }} size={ 12 }/> : <Download/> }
                                    onClick={ handleSubmit( onSubmit ) }
                                >
                                    Descargar mi tarjeta
                                </Button>
                            </motion.div>
                        </motion.span>
                    </motion.div>
                </form>
                <motion.div
                    initial={{
                        y: 20,
                        opacity: 0
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: {
                            ...inputTransition,
                            delay: 4,
                        }
                    }}
                >
                    <Card sx={{ my: 8 }} elevation={ 4 }>
                        <CardActionArea onClick={ () => navigate('/')}>
                            <CardContent>
                                <Stack>
                                    <Typography color="text.primary" variant="body2">
                                        ¿Eres emprendedor/a?
                                    </Typography>
                                    <Typography color="text.secondary" variant="caption">
                                        Crea tu perfil en OhMyZone!,
                                        recibe mensajes y publica tus productos o servicios.
                                    </Typography>
                                    <Button sx={{
                                        mt: 1,
                                        borderRadius: 3.5,
                                        textTransform: 'none',
                                    }} size="small" color="secondary" variant="outlined">
                                        ¡Regístrate gratis!
                                    </Button>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </motion.div>
            </Container>
        </Layout>
    )
}

export default withTheme(Tarjeta);